






import { Component, Vue } from 'vue-property-decorator';
import { IsPC, getQueryVariable } from '@/utils/lib';
import { appConfigModule } from '@/store/modules/appConfig';
import { otherModule } from '@/store/modules/other';

@Component({
    name: 'App'
})
export default class extends Vue {
    // 读取样式表设置样式
    private created() {
        const link = document.createElement('link');
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('type', 'text/css');
        link.setAttribute('href', '/theme.css');
        document.head.appendChild(link);
    }

    private async mounted() {
        await this.getSource();
        await this.getAgentClient();
        const url = window.location.href;
        if (!IsPC() && url.indexOf('third-party') === -1) {
            if (url.indexOf('login/register') !== -1) {
                window.location.href = this.appConfig.vueAdmin + `/H5/pages/login/select-ligin-type?state=1&RegisteredSource=${this.Source}&AgentClientId=${this.Agent}`;
                // window.location.href = `http://192.168.1.42:8080/H5/pages/login/select-ligin-type?state=1&RegisteredSource=${this.Source}&AgentClientId=${this.Agent}`;
                return;
            }
            // window.location.href = appFig.vueAdmin + '/H5';
            window.location.href = this.appConfig.vueAdmin + `/H5?state=1&RegisteredSource=${this.Source}&AgentClientId=${this.Agent}`;
            // window.location.href = `http://192.168.1.42:8080/H5?state=1&RegisteredSource=${this.Source}&AgentClientId=${this.Agent}`;
        }
    }

     // 获取注册来源
    // 注册来源(淘宝注册:1，LINE注册:2，脸书注册:3, 抖音注册:4，小红书注册:5，TikTok注册:6, 谷歌注册:7，Popln注册:8，网站注册:9,微信公众号注册:10)
    private getSource() {
        let time: any = 0;
        let count = 0;
        return new Promise((resolve) => {
            time = setInterval(() => {
                const RegisteredSource = getQueryVariable('RegisteredSource');
                count++;
                if (count > 10) {
                    clearInterval(time);
                    resolve(true);
                }
                if (RegisteredSource) {
                    otherModule.setRegisteredSource(RegisteredSource);
                    clearInterval(time);
                    resolve(true);
                }
            }, 100);
        });
    }

    // 获取代理来源
    private getAgentClient() {
        let time: any = 0;
        let count = 0;
        return new Promise((resolve) => {
            time = setInterval(() => {
                const agentClientId = getQueryVariable('AgentClientId');
                count++;
                if (count > 10) {
                    clearInterval(time);
                    resolve(true);
                }
                if (agentClientId) {
                    otherModule.setAgentClientId(agentClientId);
                    clearInterval(time);
                    resolve(true);
                }
            }, 100);
        });
    }

    // 获取来源
    private get Source() {
        return otherModule.registeredSource;
    }

    // 获取代理id
    private get Agent() {
        return otherModule.agentClientId || '';
    }

    public get appConfig() {
        return appConfigModule.appConfig;
    }
}
